
import { Component, Vue } from 'vue-property-decorator'

import LogoBtn from '~/components/shared/LogoBtn.vue'
import { Login } from '~/types/login'

@Component({
  components: {
    LogoBtn
  }
})
export default class OtherLogin extends Vue {
  private get textMessagePerAccount(): Login.AccountTextMessage {
    const pathName = this.$route.name
    const hasAccountText = { title: '', button: '', link: '' }
    if (pathName === 'login') {
      hasAccountText.title = `talentbookアカウントをお持ちでない場合`
      hasAccountText.button = `アカウント新規作成`
      hasAccountText.link = `/signup`
    } else if (pathName === 'signup') {
      hasAccountText.title = `すでにアカウントをお持ちの場合`
      hasAccountText.button = `talentbookにログイン`
      hasAccountText.link = `/login`
    }
    return hasAccountText
  }
}
