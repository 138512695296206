
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class infomationAlert extends Vue {
  @Prop({
    type: Boolean,
    required: true
  })
  private display!: boolean

  @Prop({
    type: String,
    required: false
  })
  private text!: string

  @Prop({
    type: [String, Array],
    required: true
  })
  private classname!: string | string[]

  @Prop({
    type: Boolean,
    required: false
  })
  private block!: boolean

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  private loginError: boolean

  private get classList() {
    return Array.isArray(this.classname) ? this.classname : [this.classname]
  }
}
