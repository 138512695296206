import { render, staticRenderFns } from "./infomationAlert.vue?vue&type=template&id=015d9e43&scoped=true"
import script from "./infomationAlert.vue?vue&type=script&lang=ts"
export * from "./infomationAlert.vue?vue&type=script&lang=ts"
import style0 from "./infomationAlert.vue?vue&type=style&index=0&id=015d9e43&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015d9e43",
  null
  
)

export default component.exports